import React from 'react'
import { Link } from 'gatsby'


const BreadCrumbElement = ({
  linkTo, label, isActive, position,
}) => (
  <li
    itemProp="itemListElement"
    itemScope
    itemType="http://schema.org/ListItem"
    className={isActive ? 'is-active' : ''}
  >
    <Link to={linkTo} itemProp="item">
      <span itemProp="name">{label}</span>
    </Link>
    <meta itemProp="position" content={position} />
  </li>
)

export default ({
  article,
}) => (
  <nav
    className="breadcrumb"
    aria-label="breadcrumbs"
    role="navigation"
    itemScope
    itemType="http://schema.org/Breadcrumb"
  >
    <ol itemScope itemType="http://schema.org/BreadcrumbList">
      <BreadCrumbElement
        linkTo="/blog"
        label="Blog"
        position="1"
      />
      <BreadCrumbElement
        linkTo="/blog"
        label={article}
        isActive={article}
        position="2"
      />
    </ol>
  </nav>
)

import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'

import './style.scss'
import Layout from '../layouts'
import BlogArticleBreadCrumb from '../components/BlogArticleBreadCrumb'

const BlogPostTemplate = ({ data, pathContext }) => {
  const post = data.markdownRemark
  const siteTitle = get(data, 'site.siteMetadata.title')
  const { previous, next } = pathContext

  return (
    <Layout>
      <section className="section">
        <div className="container article-container">
          <BlogArticleBreadCrumb
            article={post.frontmatter.title}
          />
          <div className="content">
            <Helmet title={`${post.frontmatter.title} | ${siteTitle}`} />
            <h1>
              {post.frontmatter.title}
            </h1>
            <p>
              {post.frontmatter.date}
            </p>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            <hr />

            <ul
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                listStyle: 'none',
                padding: 0,
              }}
            >
              {previous && (
                <li>
                  <Link to={previous.fields.slug} rel="prev">
                    {`← ${previous.frontmatter.title}`}
                  </Link>
                </li>
              )}

              {next && (
                <li>
                  <Link to={next.fields.slug} rel="next">
                    {`${next.frontmatter.title} →`}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
